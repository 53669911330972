import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent, NavbarComponentItem } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TiresComponent } from './tires/tires.component';
import { TireComponent } from './tires/tire/tire.component';
import { SliedeshowComponent } from './sliedeshow/sliedeshow.component';
import { DashcardComponent } from './dashcard/dashcard.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RatingComponent } from './rating/rating.component';
import { GalleryComponent } from './gallery/gallery.component';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { DocViewerComponent } from './doc-viewer/doc-viewer.component';
import { FilterByFileTypePipe } from 'app/pipes/filter-by-file-type.pipe';
import { WeatherComponent } from './weather/weather.component';
import { GalleryResultComponent } from './gallery-result/gallery-result.component';
import { PipesModule } from 'app/pipes/pipes.module';
import { BlueprintComponent } from './blueprint/blueprint.component';
import { C360Component } from './c360/c360.component';
import { FormComponent } from './form/form.component';

import { MatFormFieldModule } from '@angular/material/form-field';

//import { RatingModule } from 'ng-starrating';
import { SearchableSelectComponent } from './searchable-select/searchable-select.component';
import { LocationSearchComponent } from './location-search/location-search.component';
import { SidebarTreeComponent } from './sidebar-tree/sidebar.component';
import { XlsButtonComponent } from './xls-button/xls-button.component';
import { MultiConfirmComponent } from './multi-confirm/multi-confirm.component';
import { DatetimeComponent } from './datetime/datetime.component';

import { AgGridModule } from 'ag-grid-angular';
import { BlueprintDanniInterniComponent } from './blueprint-danni-interni/blueprint-danni-interni.component';

//import { NgScrollbarModule } from 'ngx-scrollbar';
@NgModule({
  imports: [
    CommonModule,
    
    RouterModule,
    PipesModule,
    MatExpansionModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatTooltipModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatIconModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatExpansionModule,
    MatRadioModule,
    MatTreeModule,
    MatBadgeModule,
    MatChipsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatMenuModule,
    
    EcoFabSpeedDialModule,
    
    AgGridModule.withComponents([]),
    //NgScrollbarModule,
  ],
  declarations: [
    XlsButtonComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SidebarTreeComponent,
    TireComponent,
    TiresComponent,
    SliedeshowComponent,
    DashcardComponent,
    GalleryComponent,
    RatingComponent,
    DocViewerComponent,
    FilterByFileTypePipe,
    WeatherComponent,
    GalleryResultComponent,
    BlueprintComponent,
    BlueprintDanniInterniComponent,
    C360Component,
    FormComponent,
    SearchableSelectComponent,
    LocationSearchComponent,
    MultiConfirmComponent,
    DatetimeComponent,
    NavbarComponentItem,
    //NgScrollbarModule,
  ],
  exports: [
    XlsButtonComponent,
    PipesModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatTooltipModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatIconModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatExpansionModule,
    MatRadioModule,
    MatTreeModule,
    MatBadgeModule,
    MatChipsModule,
    MatMenuModule,
    
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SidebarTreeComponent,
    TireComponent,
    TiresComponent,
    SliedeshowComponent,
    DashcardComponent,
    GalleryComponent,
    RatingComponent,
    
    EcoFabSpeedDialModule,
    
    DocViewerComponent,
    FilterByFileTypePipe,
    WeatherComponent,
    GalleryResultComponent,
    BlueprintComponent,
    BlueprintDanniInterniComponent,
    C360Component,
    FormComponent,
    //RatingModule,
    MatSelectModule,
    MatFormFieldModule,
    
    SearchableSelectComponent,
    LocationSearchComponent,
    MultiConfirmComponent,
    DatetimeComponent,
    
    MatTabsModule,

    NavbarComponentItem,
    //NgScrollbarModule,
    AgGridModule,


  ]
})
export class ComponentsModule { }
