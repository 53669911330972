<div class="row" *ngIf="!disabled" [ngClass]="{'hidden': hideUploader}">
  <div class="col-4 dropzone" [ngxFileDropzone]="true" (fileDrop)="dropFile($event)">Drop a file in this zone.</div>
  <div class="col-2 btnzone"><button type="button" id="btn_upload" onclick="document.getElementById('file').click();"
      class="btn btn-info">CARICA ALLEGATO...</button></div>
</div>

<button mat-button *ngIf="showFotoVetrina" type="button" value="Foto vetrina" (mouseenter)="mouseEnter($event)"
  (mouseleave)="mouseLeave($event)" (click)="tab = 'slideshow'"
  [ngClass]="{'btn': true, 'btn-success': tab=='slideshow',   'btn-default': tab!='slideshow' }">
  <i class="material-icons">slideshow</i>
</button>

<button mat-button *ngIf="showFotoVetrina && role!='CLIENTE'" type="button" value="Foto vetrina"
  (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)" (click)="tab = 'foto_vetrina'"
  [ngClass]="{'btn': true, 'btn-success': tab=='foto_vetrina',   'btn-default': tab!='foto_vetrina' }">
  <i class="material-icons">directions_car</i>
</button>

<button mat-button *ngIf="showAltriElementi" type="button" value="Altri elementi" (mouseenter)="mouseEnter($event)"
  (mouseleave)="mouseLeave($event)" (click)="tab = 'altri_elementi'"
  [ngClass]="{'btn': true, 'btn-success': tab=='altri_elementi', 'btn-default': tab!='altri_elementi' }">
  <i class="material-icons">insert_photo</i>
</button>

<button mat-button *ngIf="img_360" type="button" value="360°" (click)="this.tab = '360';"
  (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='360', 'btn-default': tab!='360' }">
  <i class="material-icons">360</i>
</button>

<button mat-button *ngIf="source?.result?.damages && source?.result?.damages?.length > 0" type="button" value="Danni"
  (click)="tab = 'danni'" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='danni', 'btn-default': tab!='danni' }">
  <i class="material-icons">sentiment_very_dissatisfied</i>
</button>

<button mat-button
  *ngIf="source?.result?.damagesInt && source?.result?.damagesInt?.length > 0 || source?.result?.damagesInt && source?.result?.damagesAll?.length > 0"
  type="button" value="Altre parti danneggiate" (click)="tab = 'danniInt'" (mouseenter)="mouseEnter($event)"
  (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='danniInt', 'btn-default': tab!='danniInt' }">
  <i class="material-icons">disabled_by_default</i>
</button>

<!--
<button mat-button *ngIf="source?.result?.damages && source?.result?.damages?.length > 0 " type="button" value="Danni" (click)="tab = 'riparazioni'"
  (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='riparazioni', 'btn-default': tab!='danni' }">
  <i class="material-icons">settings_ethernet</i>
</button>
-->
<button mat-button *ngIf="source?.result?.attachments && source?.result?.attachments?.length > 0" type="button"
  value="foto libere" (click)="tab = 'camera'" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='camera', 'btn-default': tab!='camera' }">
  <i class="material-icons">camera_alt</i>
</button>

<button mat-button *ngIf="source?.result?.glasses && source?.result?.glasses.length>0" type="button" value="Cristalli"
  (click)="tab = 'cristalli'" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='cristalli', 'btn-default': tab!='cristalli' }">
  <i class="material-icons">broken_image</i>
</button>


<button mat-button *ngIf="source?.result?.documenti && source?.result?.documenti.length>0" type="button"
  value="Documenti" (click)="tab = 'documenti'" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='documenti', 'btn-default': tab!='documenti' }">
  <i class="material-icons">folder</i>
</button>

<button mat-button *ngIf="source?.allegati && source?.allegati.length>0" type="button" value="Altri file"
  (click)="tab = 'altri_file'" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='altri_file', 'btn-default': tab!='altri_file' }">
  <i class="material-icons">attach_file</i>
</button>

<button mat-button *ngIf="source?.result?.tiresData && isTiresDataPresent()" type="button" value="Ruote"
  (click)="tab = 'tires'" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='tires', 'btn-default': tab!='tires' }">
  <i class="material-icons">album</i>
</button>

<button mat-button *ngIf="source?.result?.optionals?.length>0" type="button" value="Optionals"
  (click)="tab = 'optionals'" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='optionals', 'btn-default': tab!='optionals' }">
  <i class="material-icons">add</i>
</button>


<button mat-button *ngIf="source?.result?.geo" type="button" value="Geo" (click)="loadMapTab()"
  (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='geo', 'btn-default': tab!='geo' }">
  <i class="material-icons">place</i>
</button>

<button mat-button *ngIf="source?.result?.archiveList?.length>0" type="button" value="Geo" (click)="tab = 'archiveList'"
  (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='archiveList', 'btn-default': tab!='archiveList' }">
  <i class="material-icons">videocam</i>
</button>



<button mat-button type="button" value="Altre info" (click)="tab = 'more_info'" (mouseenter)="mouseEnter($event)"
  (mouseleave)="mouseLeave($event)"
  [ngClass]="{'btn': true, 'btn-success': tab=='more_info', 'btn-default': tab!='more_info' }">
  <i class="material-icons">info</i>
</button>


{{tab_hover}}


<div class="row" *ngIf="tab=='slideshow'">
  <app-sliedeshow [images]="source?.result?.fotoVetrina | presentImage"></app-sliedeshow>
</div>


<div *ngIf="tab=='foto_vetrina'" style="height:100%">


  <table style="height:100%">


    <tr>
      <td>
        <div class="growx" *ngIf="!source?.result?.fotoVetrina[5].url.includes('null')">

          <button type="button" color="warn" mat-icon-button *ngIf="!disabled" (click)="deleteFotoVetrina(5)"
            style="position:absolute; top:0; right:20px;"><i class="material-icons">delete_forever</i></button>

          <a class="halfwidth" (click)="openFile(source.result.fotoVetrina,5)">
            <img
              [src]="source?.result?.fotoVetrina[5].url ? source?.result?.fotoVetrina[5].url : source?.result?.fotoVetrina[5].thumb"
              class="halfwidth">
          </a>
        </div>
        <div *ngIf="source?.result?.fotoVetrina[5].url.includes('null')">&nbsp;</div>
      </td>
      <td>
        <div class=" growx" *ngIf="!source?.result?.fotoVetrina[6].url.includes('null')">

          <button type="button" color="warn" mat-icon-button *ngIf="!disabled" (click)="deleteFotoVetrina(6)"
            style="position:absolute; top:0; right:20px;"><i class="material-icons">delete_forever</i></button>

          <a class="halfwidth" (click)="openFile(source.result.fotoVetrina,6)">
            <img style="    width: 85%!important;"
              [src]="source?.result?.fotoVetrina[6].url ? source?.result?.fotoVetrina[6].url : source?.result?.fotoVetrina[6].thumb"
              class="halfwidth">
          </a>
        </div>
        <div *ngIf="source?.result?.fotoVetrina[6].url.includes('null')">&nbsp;</div>
      </td>
      <td>
        <div class="growx" *ngIf="!source?.result?.fotoVetrina[7].url.includes('null')">

          <button type="button" color="warn" mat-icon-button *ngIf="!disabled" (click)="deleteFotoVetrina(7)"
            style="position:absolute; top:0; right:20px;"><i class="material-icons">delete_forever</i></button>

          <a class="halfwidth" (click)="openFile(source.result.fotoVetrina,7)">
            <img
              [src]="source?.result?.fotoVetrina[7].url ? source?.result?.fotoVetrina[7].url : source?.result?.fotoVetrina[7].thumb"
              class="halfwidth">
          </a>
        </div>
      </td>
    </tr>


    <tr>
      <td>
        <div class=" growx" *ngIf="!source?.result?.fotoVetrina[4].url.includes('null')">

          <button type="button" color="warn" mat-icon-button *ngIf="!disabled" (click)="deleteFotoVetrina(4)"
            style="position:absolute; top:0; right:20px;"><i class="material-icons">delete_forever</i></button>

          <a class="halfwidth" (click)="openFile(source.result.fotoVetrina,4)">
            <img
              [src]="source?.result?.fotoVetrina[4].url ? source?.result?.fotoVetrina[4].url : source?.result?.fotoVetrina[4].thumb"
              class="halfwidth">
          </a>
        </div>
        <div *ngIf="source?.result?.fotoVetrina[4].url.includes('null')">&nbsp;</div>
      </td>
      <td>
        <div>
          <img [src]="'./assets/img/center/center_'+source?.result?.vehType+'.png'" class="halfwidth">
        </div>
      </td>
      <td>
        <div class="growx" *ngIf="!source?.result?.fotoVetrina[0].url.includes('null')">

          <button type="button" color="warn" mat-icon-button *ngIf="!disabled" (click)="deleteFotoVetrina(0)"
            style="position:absolute; top:0; right:20px;"><i class="material-icons">delete_forever</i></button>

          <a class="halfwidth" (click)="openFile(source.result.fotoVetrina,0)">
            <img
              [src]="source?.result?.fotoVetrina[0].url ? source?.result?.fotoVetrina[0].url : source?.result?.fotoVetrina[0].thumb"
              class="halfwidth">
          </a>
        </div>
        <div *ngIf="source?.result?.fotoVetrina[0].url.includes('null')">&nbsp;</div>
      </td>
    </tr>


    <tr>
      <td>
        <div class=" growx" *ngIf="!source?.result?.fotoVetrina[3].url.includes('null')">

          <button type="button" color="warn" mat-icon-button *ngIf="!disabled" (click)="deleteFotoVetrina(3)"
            style="position:absolute; top:0; right:20px;"><i class="material-icons">delete_forever</i></button>

          <a class="halfwidth" (click)="openFile(source.result.fotoVetrina,3)">
            <img
              [src]="source?.result?.fotoVetrina[3].url ? source?.result?.fotoVetrina[3].url : source?.result?.fotoVetrina[3].thumb"
              class="halfwidth">
          </a>
        </div>
        <div *ngIf="source?.result?.fotoVetrina[3].url.includes('null')">&nbsp;</div>
      </td>
      <td>
        <div class="growx" *ngIf="!source?.result?.fotoVetrina[2].url.includes('null')">

          <button type="button" color="warn" mat-icon-button *ngIf="!disabled" (click)="deleteFotoVetrina(2)"
            style="position:absolute; top:0; right:20px;"><i class="material-icons">delete_forever</i></button>

          <a class="halfwidth" (click)="openFile(source.result.fotoVetrina,2)">
            <img style="    width: 85%!important;"
              [src]="source?.result?.fotoVetrina[2].url ? source?.result?.fotoVetrina[2].url : source?.result?.fotoVetrina[2].thumb"
              class="halfwidth">
          </a>
        </div>
        <div *ngIf="source?.result?.fotoVetrina[2].url.includes('null')">&nbsp;</div>
      </td>
      <td>
        <div class="growx" *ngIf="!source?.result?.fotoVetrina[1].url.includes('null')">

          <button type="button" color="warn" mat-icon-button *ngIf="!disabled" (click)="deleteFotoVetrina(1)"
            style="position:absolute; top:0; right:20px;"><i class="material-icons">delete_forever</i></button>

          <a class="halfwidth" (click)="openFile(source.result.fotoVetrina,1)">
            <img
              [src]="source?.result?.fotoVetrina[1].url ? source?.result?.fotoVetrina[1].url : source?.result?.fotoVetrina[1].thumb"
              class="halfwidth">
          </a>
        </div>
        <div *ngIf="source?.result?.fotoVetrina[1].url.includes('null')">&nbsp;</div>
      </td>
    </tr>


  </table>

</div>

<div class="row" *ngIf="tab=='altri_elementi'">
  <!--
  <div class="col" class="col-12 col-sm-3">
    <img [src]="'./assets/img/altri_elementi/altri_elementi_' + source.result.vehType + '.png'" class="halfwidth">
  </div>
-->
  <div class="col">


    <div class="row">
      <div class="col-12 col-sm-6 col-md-3 bshadow growx" *ngFor="let a of source.result.altriElementi ; index as i ">



        <button type="button" color="warn" mat-icon-button (click)="deleteAltriElementi(i)"
          *ngIf="!a.url.includes('null') && !disabled" style="position:absolute; top:0; right:20px;"><i
            class="material-icons">delete_forever</i></button>

        <a class="halfwidth" (click)="openFile(source.result.altriElementi,i)" *ngIf="!a.url.includes('null')">
          <img [src]="a.url ? a.url : a.thumb" class="halfwidth">
        </a>

        <div class="img-footer" (click)="openFile(i)" *ngIf="a.fileName">
          {{ a.fileName }} - {{humanize(a.size)}}
        </div>

        <div class="img-footer" (click)="openFile(i)" *ngIf="a.label" style="padding-left:10px">
          {{(i+1)}} - {{ a.label }}
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="tab=='cristalli'">

  <div *ngFor="let a of source.result.glasses; index as i">
    <h3>{{getGlassName(a.id)}}</h3>
    <div class="row">

      <div class="col-4 ">
        <a class="halfwidth">
          <img [src]="'/assets/img/glasses/cristalli_' + a.id + '.png'" class="halfwidth">
        </a>
      </div>

      <div class="col-4 ">
        <a class="halfwidth" (click)="openGlass(a)">
          <img [src]="a.img1.url ? a.img1.url : a.img1.thumb" class="halfwidth">
        </a>
      </div>

      <div class="col-4 ">
        <a class="halfwidth" (click)="openGlass(a)">
          <img [src]="a.img2.url ? a.img2.url : a.img2.thumb" class="halfwidth">
        </a>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="tab=='documenti'">

  <div class="col-12 col-sm-6 col-md-3 bshadow growx" *ngFor="let a of source.result.documenti; index as i ">

    <button type="button" color="warn" mat-icon-button (click)="deleteDocumenti(i)"
      *ngIf="!a.url.includes('null') && !disabled" style="position:absolute; top:0; right:20px;"><i
        class="material-icons">delete_forever</i></button>

    <a class="halfwidth" (click)="openFile(source.result.documenti,i)">
      <img [src]="a.url ? a.url : a.thumb" class="halfwidth">
    </a>

    <div class="img-footer" *ngIf="a.fileName">
      {{ a.fileName }} - {{humanize(a.size)}}
    </div>

    <div class="img-footer" *ngIf="a.label" style="padding-left:10px">
      {{ a.label }}
    </div>

  </div>
</div>

<div class="row" *ngIf="tab=='altri_file'">

  <div style="height: 8vw" class="col-12 col-sm-6 col-md-3 bshadow growx"
    *ngFor="let a of source.allegati | filterByFileType: ['img','pdf','oth', showPP ? 'pp' : 'xx'] ; index as i ">


    <button type="button" color="warn" mat-icon-button (click)="deleteAllegati(a)"
      *ngIf="!a.url.includes('null') && !disabled" style="position:absolute; top:0; right:20px;"><i
        class="material-icons">delete_forever</i></button>

    <a class="halfwidth" (click)="openFile(source.allegati,i)">
      <img [src]="isImage(a)" class="halfwidth" style="object-fit: cover">
    </a>

    <div class="img-footer" *ngIf="a.fileName">
      {{ a.fileName }} - {{humanize(a.size)}}
    </div>

    <div class="img-footer" *ngIf="a.label" style="padding-left:10px">
      {{ a.label }}
    </div>

  </div>
</div>


<div class="row" *ngIf="tab=='danni'">
  <div class="col-12">
    <app-blueprint [setValue]="source.result" [disabled]='true' (onSelected)=openFileEvent($event)
      style="max-width:75vw!important"></app-blueprint>
  </div>
</div>

<div class="row" *ngIf="tab=='danniInt'">
  <div class="col-12">
    <app-blueprint-danni-interni 
      [setValue]="source.result" 
      [disabled]="true" 
      (onSelected)="openFileEvent($event)"
      style="max-width:75vw!important">
    </app-blueprint-danni-interni>
  </div>
</div>




<div class="row" *ngIf="tab=='riparazioni'">
  <div class="col-12" *ngIf="source?.result?.damages">

    <div *ngFor="let d of source.result.damages; index as i;">

      <div class="card">

        <div class="row">
          <div class="col-3">
            <img style="width:128px; display:block; margin:auto;" [src]="d.img2.thumb">
          </div>
          <div class="col-6">
            <p style="font-weight: bold; width:100%; text-align: center;">{{i+1}}.0 - </p>
          </div>
          <div class="col-3">
            <img style="width:128px; display:block; margin:auto;" *ngIf=" d?.img_rip" [src]=" d?.img_rip?.thumb">
          </div>
        </div>

      </div>

      <div *ngFor="let ed of d.extra; index as j;">
        <div class="card">

          <div class="row">
            <div class="col-3">
              <img style="width:128px; display:block; margin:auto;" [src]="ed.img2.thumb">
            </div>

            <div class="col-6">
              <p style="font-weight: bold; width:100%; text-align: center;">{{i+1}}.{{j+1}} - {{ed.descr}}</p>
            </div>

            <div class="col-3">
              <img style="width:128px; display:block; margin:auto;" *ngIf="ed?.img_rip" [src]="ed?.img_rip?.thumb">
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>

<div class="row" *ngIf="tab=='camera'">

  <div class="col-12 col-sm-6 col-md-3 bshadow growx" *ngFor="let a of source.result.attachments; index as i ">

    <button type="button" color="warn" mat-icon-button (click)="deleteAttachments(i)"
      *ngIf="!a.url.includes('null') && !disabled" style="position:absolute; top:0; right:20px;"><i
        class="material-icons">delete_forever</i></button>

    <a class="halfwidth" (click)="openFile(source.result.attachments,i)">
      <img [src]="a.url ? a.url : a.thumb" class="halfwidth">
    </a>

    <div class="img-footer" *ngIf="a.fileName">
      {{ a.fileName }} - {{humanize(a.size)}}
    </div>

  </div>
</div>

<div class="row" *ngIf="tab=='tires'">
  <app-tires *ngIf="source?.result?.tiresData" [(value)]="source.result.tiresData" (onChange)="tiresDataChanged()"
    (onOpenViewer)="openViewer($event)">
  </app-tires>
</div>

<div class="row" *ngIf="tab=='optionals'">
  <div style="padding:10px">
    <table style="width:100%">
      <tr *ngFor="let e of source?.result?.optionals">
        <td style="padding:10px">{{e.code}} - {{e.descr}}</td>
        <td style="padding:10px">{{e.presence ? 'PRESENTE' : '- - - - -'}}</td>
      </tr>
    </table>
  </div>
</div>

<div class="row" *ngIf="tab=='geo'" style=" padding:0; margin:0; height:50vh">
  <div class="col-12" style=" padding:0; margin:0; height:50vh">
    <div id="map" style=" padding:0; margin:0; height:50vh"></div>
  </div>
  <br>
  <p style="font-weight: bold;">
    Geolocalizzazione: {{this.source.result.geo.latitude }}, {{this.source.result.geo.longitude}}</p>

</div>

<div class="row" *ngIf="tab=='360'" style=" padding:0; margin:0; height:50vh">

  <app-c360 style="background-color: gray" style="width: 100%; height:50vh" [(setURL)]="img_360" *ngIf=" tab=='360'"
    [setImages]="source?.result?.altriElementi">
  </app-c360>

</div>


<div class="row" *ngIf="tab=='more_info'" style=" padding:0; margin:0; height:50vh">


  <br>
  <br>
  <br>


  <div class="row" style="width:100%">

    <div class="col-6">

      <mat-card>
        <mat-card-header>
          <mat-card-title style="font-size: 120%; white-space: nowrap; font-weight: bold;">Documenti - Chiavi -
            Codici<br><br>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>

          <mat-checkbox [(ngModel)]="source.result.documenti_chiavi_codici.carta_circolazione"
            [labelPosition]="'before'" [disabled]="true">
            Carta Circolazione
          </mat-checkbox><br>


          <mat-checkbox [(ngModel)]="source.result.documenti_chiavi_codici.chiave1" [labelPosition]="'before'"
            [disabled]="true">
            1^ Chiave
          </mat-checkbox><br>


          <mat-checkbox [(ngModel)]="source.result.documenti_chiavi_codici.chiave2" [labelPosition]="'before'"
            [disabled]="true">
            2^ Chiave
          </mat-checkbox><br>


          <mat-checkbox [(ngModel)]="source.result.documenti_chiavi_codici.lavato" [labelPosition]="'before'"
            [disabled]="true">
            Lavato
          </mat-checkbox><br>


          <mat-checkbox [(ngModel)]="source.result.documenti_chiavi_codici.odori" [labelPosition]="'before'"
            [disabled]="true">
            Odori Forti e persistenti (Fumo)
          </mat-checkbox><br>


          Note:<br>
          <span style="width:100%; margin-left:40px;">
            {{source.result.documenti_chiavi_codici.note}}
          </span>

        </mat-card-content>

      </mat-card>

    </div>

    <div class="col-6">

      <mat-card>
        <mat-card-header>
          <mat-card-title style="font-size: 120%; white-space: nowrap; font-weight: bold;">Dotazioni<br><br>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>

          <mat-checkbox [(ngModel)]="source.result.dotazioni.triangolo"
            [labelPosition]="'before'" [disabled]="true">
            Triangolo
          </mat-checkbox><br>
          
          <mat-checkbox [(ngModel)]="source.result.dotazioni.attrezzi"
            [labelPosition]="'before'" [disabled]="true">
            Attrezzi sost. pneumatici
          </mat-checkbox><br>

          <mat-checkbox [(ngModel)]="source.result.dotazioni.catene"
            [labelPosition]="'before'" [disabled]="true">
            CATENE da neve
          </mat-checkbox><br>

          <mat-checkbox [(ngModel)]="source.result.dotazioni.gancio"
            [labelPosition]="'before'" [disabled]="true">
            GANCIO traino
          </mat-checkbox><br>

          <mat-checkbox [(ngModel)]="source.result.dotazioni.cdsd"
            [labelPosition]="'before'" [disabled]="true">
            CD e/o SD navigatore
          </mat-checkbox><br>

          <mat-checkbox [(ngModel)]="source.result.dotazioni.carta_carburante"
            [labelPosition]="'before'" [disabled]="true">
            Carta Carburante
          </mat-checkbox><br>


          <span>
            Km: <span style="float:right; padding-right:20px; font-weight: bold;">{{source.result.km | number}}</span>
          </span> <br>


          <span>
            Carburante: <span style="float:right; padding-right:20px; font-weight: bold;">{{source.result.carburante |
              number}} % </span>
          </span> <br>

        </mat-card-content>

      </mat-card>

    </div>


    <div class="col" *ngIf="source.result.grandinato">

      <mat-card>

        <mat-card-content>

          <h5>Veicolo Grandinato</h5>

          {{source.result.grandinato_note}}

          </mat-card-content>
          </mat-card>
          </div>

  </div>

  <div class="row">

    <div class="col-12">
      <br>
      <hr> <br>

      <mat-card>


        <mat-card-content>

          <div class="row">
            <div class="col-4">

              <span style="font-size: 120%; white-space: nowrap; font-weight: bold;">Firma<br></span>

              Luogo: {{ source.result.signature.luogo }} - Nome: {{ source.result.signature.nome }} - Cognome: {{
              source.result.signature.cognome }} <br>

              Data: {{ source.result.signature.data }} - Ora: {{ source.result.signature.ora }} <br>

              Indirizzo: {{ source.result.signature.indirizzo }} - Telefono: {{ source.result.signature.telefono }} <br>
            </div>


            <div class="col-8">
              <img style="width:100%" [src]="source.result.signature.signature">
            </div>

          </div>


        </mat-card-content>
      </mat-card>

    </div>
  </div>



</div>




<div class="row" *ngIf="tab=='archiveList'" style=" padding:0; margin:0; height:50vh">

  <div class="row" *ngIf="source?.result?.archiveList?.length > 0" style="padding:15px">
    <div class="col" *ngFor="let v of source?.result?.archiveList; index as i">
      <img style="width:100%; cursor: pointer;" [src]="v.thumb" (click)="openVideo(v.id)">
    </div>
  </div>

</div>